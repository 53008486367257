.header {
    position:fixed;
    height:70px;
    width:100%;
    top:0;
    left:0;
    z-index:1;
    transition: .3s ease-in;
    overflow:hidden;
    animation: 1s ease-out 0s 1 slideInFromTop;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    
    
}

.header .navbar{
    display:flex;
    justify-content: right;
    align-items: center;
    max-width: 100%;
    background-color: #27496D;
    
    margin:auto;
    height:100%;
    
    font-size: 20px;
    
    
}

.header .navbar-transparent{
    display:flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    background-color: none;
    margin:auto;
    height:100%;
    padding:0 4rem;
    font-size: 20px;
    transition: 1s ease-out;
    
    
}

.header .nav-item:hover{
    background-color: #0C7B93;
    cursor:pointer;
    transition:0.2s ease-in;
}


.header .navbar img{
    width: 250px;
    height:auto;

}
.header .nav-menu a{
    color:white;
    
}

@keyframes animate2{
    0%, 18%,20%,50.1%,60%,65.1%,89%,90.1%,92%
    {
        color:#ff46da;
    }
    18.1%,20.1%,30%,50%,60.1%, 65%, 80.1%, 90%, 92.1%, 100%{
        color:#ff00cc;
        box-shadow: 0 0 010px blue,
        0 0 20px blue,
        0 0 40px blue,
        0 0 80px blue,
        0 0 160px blue,
    }
}

.header .nav-menu{
    display:flex;
    

}

.header .nav-item{
    padding:2rem;
    font-weight: 1;
    text-decoration: none;
}
.header .nav-item .nav-num{
color:#00A8CC;
}


.header .nav-item a:hover{
    padding: bottom 12px;
    
    font-weight:bold;
   
   
    cursor:pointer;
   
     
    
}

.hamburger{
    display:none;

}

@media screen and (max-width:940px){
    .header {
        max-width:100%;
        background-color: rgb(0, 0, 0);

    }
    .header .navbar{
        max-width: 100%;
        padding-left:1rem;
    }
    .hamburger{
        display:flex;
        margin-right: auto;
        left:0;
    }
    .nav-menu{
        position:fixed;
        left:-100%;
        top:3rem;
        flex-direction: column;
        background-color: #27496D;
        width:30%;
        
        z-index: 999;
        text-align: center;
        transition: .3s;

    }
    .nav-menu.active{
        left:0;
    }
    .nav-item{
        margin:0.5rem 0;

    }

    .header .nav-item{
        padding:1rem;
    }
}


 
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

 












