.about-page{
  
 
 position:relative;
 height:100vh;
 padding-top:100px;
 
  

}

/* .about-images{
   
  background-color: transparent;
  width: 50%;
  height:100%;
  float:right;
} */
 
.about-description{
  color:white;
  
  width: 100%;
  height:100%; 
   
}



 

.about-avatar{
    
   width:600px;
   float:left;
   margin-left:100px;
   
 
 
 
}

.about-box{
  float:right;
  margin-top:50px;
  margin-right:100px;
  padding:1rem;

  /* border:2px solid #00A8CC; */
  
  width:50%;
  height:400px;
   
  
   
  background-color:#142850;

  animation-name:moveLeft;
  
  animation-duration: 2s;
  
  
}

@keyframes moveLeft{
  0%{
      opacity: 0;
      transform:translateX(-150px);
  }
  100%{
      opacity:1;
      transform:translate(0);
  }

}

.about-box p{
  padding-bottom:2rem;
  font-size:16px;
}

.about-header{
 
  text-align:left;
   
  padding-bottom:2rem;
  font-size:30px;
}
 

.about-textarea{
  
  width:100%;
  height:100%;
  float:left;
  
}
.about-avatar-area{
  width:40%;
  height:100%;
  float:left;
  padding-top:100px;
  
}
/* .about-images{
  color:white;
  margin-top:200px;
} */

 

.skills-grid{
   
  display:grid;
 
   
   
  grid-template-columns: repeat(8, 1fr);
}

.skill-icon{
  font-size:10px;
  padding-bottom:1rem;
}
.tooltip{
  visibility:hidden;
  visibility: hidden;
  font-size:15px;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.skill-icon:hover{
  color:#00A8CC;

}
 
.skill-icon:hover .tooltip{
  visibility: visible;
}


  @keyframes typing {
    from { visibility:hidden }
    to { visibility: visible }
  }

 .header-num{
  color:#00A8CC;
  font-weight:normal;
 }

.about-text1{
  font-size:20px;
}

@media screen and (max-width:720px){
  .about-avatar-area{
    visibility:hidden;
  }
  .tech-text{
    visibility:hidden;
  }

  .skills-grid{
    visibility:hidden;
  }

}

 

@media screen and (max-width:1200px){
  .about-avatar-area{
 
    display:none;
  }
  .about-avatar{
    visibility:hidden;
    width:0;
    height:0;
    display:none;
  }
  .about-header{
    font-size:30px;
    text-align:center;
    margin-left:0;
     
    width:100%;
  }
  .about-text1{
    font-size:20px;
    float:left;
  }
  .about-box{
    position:absolute;
    left:25%;
  }
}