.canvas{
  height:100vh;
  width:100%;

}

.box-container{
 
    height:100vh;
    width:50%;
    float:right;
    position:relative;
}